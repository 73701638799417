<template>
  <div class="component-fullwidth">
    <setting-page />
  </div>
</template>

<script>

import SettingPage from '@/views/Universal/Settings/SettingPage.vue';

export default {
  name: 'TalentSettings',
  components: {
    SettingPage,
  },

};
</script>
